import React, { type PropsWithChildren } from 'react';

export interface GuardProps extends PropsWithChildren {
  condition: boolean;
  fallback: React.ReactNode;
}

export default function Guard({ condition, fallback, children }: GuardProps): React.JSX.Element {
  return <>{condition ? children : fallback}</>;
}
