'use client';
import { navigationIsOpen } from '@/context/atoms/navigation/NavigationIsOpen';
import { userPermissionsAtom } from '@/context/atoms/UserPermissions';
import { type Module, useAppModules } from '@/context/modules';
import { HomeRounded } from '@mui/icons-material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { type SetStateAction, useAtom } from 'jotai';
import { type Route } from 'next';
import { useRouter } from 'next/navigation';
import React from 'react';

const isKeyboardEvent = (event: React.KeyboardEvent | React.MouseEvent): event is React.KeyboardEvent =>
  event.type === 'keydown';

export const toggleDrawer =
  (open: boolean, setOpen: (update: SetStateAction<boolean>) => void) =>
  (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event !== undefined && isKeyboardEvent(event) && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(open);
  };

export default function NavigationDrawer(): React.JSX.Element {
  const [isOpen, setOpen] = useAtom(navigationIsOpen);
  const [anyNodePermissions] = useAtom(userPermissionsAtom);
  const { getAccessibleModules } = useAppModules();

  const router = useRouter();

  const handleClick = (route: Route): void => {
    router.push(route);
  };

  const list = (): React.JSX.Element => (
    <Box
      sx={{ width: 'auto' }}
      role="presentation"
      onClick={toggleDrawer(false, setOpen)}
      onKeyDown={toggleDrawer(false, setOpen)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              handleClick('/');
            }}
          >
            <ListItemIcon>
              <HomeRounded />
            </ListItemIcon>
            <ListItemText primary={'Dashboard'} />
          </ListItemButton>
        </ListItem>
        {getAccessibleModules(anyNodePermissions).map(({ title, href, icon: Icon }: Module, i: number) => (
          <ListItem disablePadding key={i}>
            <ListItemButton
              onClick={() => {
                handleClick(href);
              }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor={'top'}
      open={isOpen}
      onClose={toggleDrawer(false, setOpen)}
      onOpen={toggleDrawer(true, setOpen)}
    >
      {list()}
    </SwipeableDrawer>
  );
}
