'use client';
import { Slide, useScrollTrigger } from '@mui/material';
import { type SlideProps } from '@mui/material/Slide';
import React from 'react';

export default function HideOnScroll({ children }: { children: SlideProps['children'] }): React.JSX.Element {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}
