import ekkoImgEkanban from '@/components/common/img/ekko_ekanban.webp';
import ekkoImgFinder from '@/components/common/img/ekko_img_finder1.webp';
import ekkoImgLinker from '@/components/common/img/ekko_linker.webp';
import ekkoImgPicker from '@/components/common/img/ekko_picker.webp';
import ekkoImgUpdater from '@/components/common/img/ekko_updater.webp';
import { hasRequiredPermissions, Permission } from '@/context/atoms/UserPermissions';
import {
  AutorenewRounded,
  CreateRounded,
  LinkRounded,
  SearchRounded,
  type SvgIconComponent,
  TouchAppRounded,
} from '@mui/icons-material';
import { type Route } from 'next';
import { type StaticImageData } from 'next/image';

export interface UseAppModules {
  appModules: Record<ModuleType, Module>;
  moduleRoutes: Record<ModuleType, Route>;
  getAccessibleModules: (permissions: Permission[]) => Module[];
  getTitleByPathname: (pathname: string) => ModuleType | null;
  findModuleByRouteSegment: (routeSegment: string) => Module | undefined;
  findModuleByRouteSegments: (routeSegments: string[]) => Module | undefined;
  hasRequiredPermissions: (permissions: Permission[], requiredPermissions: Permission[]) => boolean;
}

export enum ModuleType {
  Picker = 'Picker',
  Linker = 'Linker',
  Finder = 'Finder',
  Updater = 'Updater',
  Ekanban = 'Ekanban',
}

export interface Module {
  title: ModuleType; // @TODO maybe string to allow custom titles?
  href: Route;
  permissions: Permission[];
  icon: SvgIconComponent;
  bgImage: StaticImageData;
}

export function useAppModules(): UseAppModules {
  const moduleRoutes: Record<ModuleType, Route> = {
    Picker: '/picker',
    Linker: '/linker',
    Finder: '/finder',
    Updater: '/updater',
    Ekanban: '/ekanban',
  };

  const appModules: Record<ModuleType, Module> = {
    Picker: {
      title: ModuleType.Picker,
      href: moduleRoutes.Picker,
      permissions: [Permission.PICKER_PROFILES_READ],
      icon: TouchAppRounded,
      bgImage: ekkoImgPicker,
    },
    Linker: {
      title: ModuleType.Linker,
      href: moduleRoutes.Linker,
      permissions: [Permission.LINK_PROFILES_READ],
      icon: LinkRounded,
      bgImage: ekkoImgLinker,
    },
    Finder: {
      title: ModuleType.Finder,
      href: moduleRoutes.Finder,
      permissions: [Permission.SHELFLABELING_READ],
      icon: SearchRounded,
      bgImage: ekkoImgFinder,
    },
    Updater: {
      title: ModuleType.Updater,
      href: moduleRoutes.Updater,
      permissions: [Permission.UPDATER_PROFILE_READ],
      icon: CreateRounded,
      bgImage: ekkoImgUpdater,
    },
    Ekanban: {
      title: ModuleType.Ekanban,
      href: moduleRoutes.Ekanban,
      permissions: [Permission.EKANBAN_READ],
      icon: AutorenewRounded,
      bgImage: ekkoImgEkanban,
    },
  };

  return {
    appModules,
    moduleRoutes,
    getAccessibleModules: (permissions: Permission[]): Module[] =>
      Object.values(appModules).filter((m: Module) => hasRequiredPermissions(permissions, m.permissions)),
    getTitleByPathname: (pathname: string): ModuleType | null =>
      Object.values(appModules).find((m) => pathname.startsWith(m.href))?.title ?? null,
    findModuleByRouteSegment: (routeSegment: string): Module | undefined =>
      Object.values(appModules).find((m) => m.href.replace('/', '') === routeSegment),
    findModuleByRouteSegments: (routeSegments: string[]): Module | undefined =>
      Object.values(appModules).find((m) => routeSegments.includes(m.href.replace('/', ''))),
    hasRequiredPermissions,
  };
}
