'use client';
import Guard from '@/components/common/Guard';
import Loading from '@/components/common/Loading';
import { apiSdkAtom } from '@/context/atoms/ApiSdk';
import { authenticationAtom } from '@/context/atoms/Authentication';
import { jwtAuthMechanismAtom } from '@/context/atoms/JWTAuthMechanism';
import { userPermissionsAtom } from '@/context/atoms/UserPermissions';
import { useAppModules } from '@/context/modules';
import { ApiSdkEvents } from '@ekkogmbh/apisdk';
import { useAtom } from 'jotai';
import { useRouter, useSelectedLayoutSegments } from 'next/navigation';
import React, { type PropsWithChildren, useCallback, useEffect, useState } from 'react';

export type PropsWithLoginSlot = { login: React.ReactNode } & PropsWithChildren;

export default function AuthGuard({ login, children }: PropsWithLoginSlot): React.JSX.Element {
  const [api] = useAtom(apiSdkAtom);
  const [authMechanism] = useAtom(jwtAuthMechanismAtom);
  const [auth, setAuth] = useAtom(authenticationAtom);
  const [permissions] = useAtom(userPermissionsAtom);
  const router = useRouter();
  const segments = useSelectedLayoutSegments();
  const [hasPermissions, setHasPermissions] = useState(false);
  const { getAccessibleModules, findModuleByRouteSegments } = useAppModules();

  // @TODO should be refactored. or find a better way.
  const checkPermissions = useCallback(
    (segments: string[]): boolean => {
      if (segments.length > 0) {
        const Module = findModuleByRouteSegments(segments);
        if (Module !== undefined) {
          return getAccessibleModules(permissions).includes(Module);
        }
      }

      return true;
    },
    [findModuleByRouteSegments, getAccessibleModules, permissions],
  );

  const handlerCallback = useCallback(() => {
    const setAuthFalse = (): void => {
      setAuth({
        isAuthenticated: false,
      });
    };

    const setAuthRefreshed = (): void => {
      setAuth(authMechanism.authentication);
    };

    return {
      setAuthFalse,
      setAuthRefreshed,
      setAuth,
    };
  }, [setAuth, authMechanism.authentication]);

  useEffect(() => {
    const { setAuthFalse, setAuthRefreshed } = handlerCallback();

    if (!checkPermissions(segments)) {
      setHasPermissions(false);
      router.push('/');
    } else {
      setHasPermissions(true);
    }

    api.on(ApiSdkEvents.AUTH_EXPIRED, setAuthFalse);
    api.on(ApiSdkEvents.AUTH_REFRESH_FAILURE, setAuthFalse);
    api.on(ApiSdkEvents.AUTH_REFRESH_SUCCESS, setAuthRefreshed);

    return () => {
      api.off(ApiSdkEvents.AUTH_EXPIRED, setAuthFalse);
      api.off(ApiSdkEvents.AUTH_REFRESH_FAILURE, setAuthFalse);
      api.off(ApiSdkEvents.AUTH_REFRESH_SUCCESS, setAuthRefreshed);
    };
  }, [api, checkPermissions, handlerCallback, router, segments]);

  if (!auth.isAuthenticated) {
    return <>{login}</>;
  }

  return (
    <Guard condition={hasPermissions} fallback={<Loading />}>
      {children}
    </Guard>
  );
}
